<template>
<section class="section">
	<div class="container is-fluid">
		<div class="columns">
			<div class="column is-10 is-offset-1-desktop">
				<h2 class="is-size-2 is-size-5-mobile"
				    v-html="data.text"></h2>
			</div>
		</div>
		<div class="columns">
			<div class="column is-10 is-offset-1-desktop">
				<p v-html="data.associations"></p>
				<div class="images-container">
					<img :src="image.image"
					     v-for="image in data.associations_repeater"
					     alt="">
				</div>
				<p v-html="data.membership"></p>
				<div class="images-container">
					<img :src="image.image"
					     v-for="image in data.membership_repeater"
					     alt="">
				</div>
				<p v-html="data.charity"></p>
				<div class="images-container">
					<img :src="image.image"
					     v-for="image in data.charity_repeater"
					     alt="">
				</div>
			</div>
		</div>
	</div>

</section>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
.column p
	margin: 20px 0 10px 15px
.section
	+desktop
		margin: 100px 0
h2
	color: $mainblue
.images-container
	display: flex
	width: 100%
	flex-wrap: wrap
	img
		height: 100px
		margin: 0 10px
		filter: grayscale(1)
		transition: filter 300ms
		+mobile
			height: 50px
		&:hover
			filter: none

</style>
