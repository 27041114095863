<template>
<section id="member"
         class="section is-paddingless">
	<div class="columns">
		<div class="column minty is-4 left-column is-size-5-touch is-size-4-mobile is-size-3-fullhd"
		     v-html="data.left_text"></div>
		<div class="column contact-container grey is-8"
		     :style="{backgroundImage: 'url('+backgroundLogo+')'}">
			<div class="rightside">
				<p class="is-size-2">{{ data.title }}</p>
				<form class="contact"
				      @submit.prevent="onSubmit">
					<input type="text"
					       placeholder="Name"
					       v-model="name">
					<input type="mail"
					       placeholder="E-mail"
					       v-model="email">
					<input type="phone"
					       placeholder="Phone number"
					       v-model="phone">
					<textarea name="message"
					          rows="8"
					          cols="80"
					          placeholder="Enquiry"
					          v-model="message"></textarea>
					<div class="submit-container">
						<input type="submit"
						       :value="sendLabel"
						       class="submit-button">
						<br>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.<br />
					</div>
					<vue-recaptcha ref="recaptcha"
					               @verify="onVerify"
					               @expired="onExpired"
					               size="invisible"
					               :sitekey="sitekey">
					</vue-recaptcha>
				</form>
			</div>
		</div>
	</div>
</section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
export default {
	components: {
		'vue-recaptcha': VueRecaptcha
	},
	data() {
		return {
			name: '',
			email: '',
			phone: '',
			message: '',
			sendLabel: 'Submit',
			infoBox: {
				type: 0, // 0 - no, 1 - success, 2 - error
				content: ''
			},
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
		}
	},
	computed: {
		backgroundLogo() {
			return this.$store.getters.getLogoBackground;
		}
	},
	props: {
		data: Object,
		printClass: Function,
		className: String,
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute()
			this.sendLabel = 'Sending...'
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			var bodyFormData = new FormData();
			bodyFormData.set('name', this.name);
			bodyFormData.set('email', this.email);
			bodyFormData.set('phone', this.phone);
			bodyFormData.set('message', this.message);
			bodyFormData.set('recaptchaToken', recaptchaToken);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config)
				.then((response) => {
					if (response.data == 'ok') {
						this.infoBox.type = 1
						this.infoBox.content = 'Email was send successfully.'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					} else {
						this.infoBox.type = 2
						this.infoBox.content = 'Server side error'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					}

				})
				.catch((err) => {
					this.infoBox.type = 2
					this.infoBox.content = 'Cannot send email, please try again later.'
					this.sendLabel = 'Send'
					setTimeout(() => {
						this.infoBox.type = 0
					}, 3000)
					console.log(getErrorMessage(err));
					//helper to get a displayable message to the user
					function getErrorMessage(err) {
						let responseBody;
						responseBody = err.response;
						if (!responseBody) {
							responseBody = err;
						} else {
							responseBody = err.response.data || responseBody;
						}
						return responseBody.message || JSON.stringify(responseBody);
					}
				})
		},
		onExpired() {
			console.log('Expired')
		}
	}
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
.column
	+touch
		margin: 0 !important
.minty
	background-color: $mint
.grey
	background-color: $grey
.rightside
	margin: 40px 70px
	color: $mainblue
	+mobile
		margin: 40px 30px
	.contact
		input:not([type="submit"]), textarea
			width: 100%
			padding: 15px
			margin: 10px 0
			background: transparent
			border: none
			border-bottom: 1px solid darken($grey, 20%)
			font-size: 1rem
.left-column
    color: white
    font-size: 2rem
    display: flex !important
    justify-content: center
    align-items: center
.submit-button
    background: transparent
    border: none
    text-transform: uppercase
    color: $mint
    letter-spacing: 1px
    padding-left: 1rem
    float: right
.contact-container
    position: relative
    background-attachment: fixed
    background-repeat: no-repeat
    background-position: 80% 30%
</style>
