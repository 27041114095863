<template>
<section class="section is-paddingless"
         :class="[className,data.custom_class]"
         :id="`section-${idNumber}`">
	<div class="container is-fluid">
		<div class="columns is-vcentered">
			<div class="column image-wrapper"
			     v-if="data.image.image"
			     :class="printClass(data,'image')"
			     :style="{order:data.image.responsive.fullhd_grid.order}">
				<figure class="image">
					<img :src="data.image.image"
					     alt="">
				</figure>
			</div>
			<div class="column content-wrapper padding-left"
			     v-if="data.content.content"
			     :class="printClass(data,'content')"
			     :style="{order:data.content.responsive.fullhd_grid.order, backgroundImage: 'url('+backgroundUrl+')'}"
			     v-html="data.content.content"></div>
		</div>
	</div>
</section>
</template>
<script>
export default {
	computed: {
		backgroundUrl() {
			let i = this.idNumber - 3;
			return require('@/assets/0' + i + 'grey.svg');
		}
	}
}
</script>
<style lang="sass">
@import "@/styles/framework/variables.sass"
.section
    .container
        +fullhd
            width: 90%
            max-width: 90%
        &.is-fluid
            margin-right: 0 !important
.content-wrapper
	h2
		color: $mainblue
		font-size: 2rem
		margin-bottom: 20px
.column
	padding: 0
.image
    height: 400px
    width: auto
    margin: 0
    text-align: inherit
    img
        height: 400px
        width: auto
.columns
	.content-wrapper
		background-repeat: no-repeat
		background-position: 0 -50px
		background-size: 200px 200px
	.padding-left
		padding-left: 50px
		+desktop-only
			padding-left: 40px
		+touch
			padding: 20px 40px
		+mobile
			background-image: none !important
.background-left .column:last-child
    background-position: 0% -100%
.background-left-big .column:last-child
    background-position: 0% -140%
    +touch
        background-position: 0% -70%
    +mobile
        background-position: 0% 0%
.background-right .column:last-child
    background-position: 60% 0%
</style>
