import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        content: null,
        loadingStatus: 'notLoading'
    },
    mutations: {
        SET_LOADING_STATUS(state, status){
            state.loadingStatus = status
        },
        SET_CONTENT(state, content) {
            state.content = content
        }
    },
    actions: {
        fetchContent(context){
            context.commit('SET_LOADING_STATUS', 'loading')
            return axios.get( process.env.VUE_APP_API+'wp-json/better-api/v1/content')
            .then((response) => {
                context.commit('SET_CONTENT', Object.freeze(response.data))
                context.commit('SET_LOADING_STATUS', 'notLoading')
            })
        }
    },
    getters : {
        getFlexibleContent: (state) => (slug) => {
            if(state.content != null){
                return state.content.pages.find(page => page.slug === slug).flexible_content
            }
        },
        getPageByPath: (state, getters) => (path) => {
            if(state.content != null){
                // remove from path public path
                path = path.replace(getters.getPublicPath, '')
                // remove last slash (in case prerendering path looks like this "/subpage/" instead of "/subpage")
                if(path[path.length - 1] == '/'){
                    path = path.substr(0, path.length-1);
                }
                // analyze path without first "/"
                let routes = path.substr(1).split('/')
                // get current locale
                let locale = getters.getLocale
                // if page has no parent
                if(routes.length == 1){
                    // if empty then set to default home
                    // find home slug of current locale
                    let home_slug = getters.getLanguages.find(lang => lang.lang === locale).home_slug

                    let route = routes[0] ? routes[0] : home_slug
                    return getters.getPageIdBySlugAndParentAndLang(route, 0, locale)
                } else if(routes.length == 2){
                    // get parents object
                    let parent = getters.getPageIdBySlugAndParentAndLang(routes[0], 0, locale)
                    return getters.getPageIdBySlugAndParentAndLang(routes[1], parent.id, locale)
                }
                // more than 2 nesting needs implement
            }
        },
        getPosts: (state, getters) => {
            if(state.content != null){
                // return state.content.posts
                let locale = getters.getLocale
                let posts = state.content.posts.filter(post => post.lang === locale)
                let result
                // if there is one item object then convert it to array
                if(posts.length === undefined){
                     result = new Array(posts)
                } else {
                    result = posts
                }
                return result
            }
        },
        getBlock: (state) => (id) => {
            if(state.content != null){
                return state.content.blocks[id].flexible_content
            }
        },
        /* Languages */
        getLanguages: (state) => {
            if(state.content != null){
                return state.content.languages
            }
        },
        getLocale: (state, getters) => {
            if(state.content != null){
                // all available languages
                const langs = getters.getOptions.languages

                // set default language
                const defaultLocale = getters.getOptions.default_language;

                // get language from url
                const urlLocale = window.location.pathname.replace(process.env.BASE_URL,'/').replace(/^\/([^\/]+).*/i,'$1');

                // if url has no known language then set default language
                const locale = langs.includes(urlLocale) ? urlLocale : defaultLocale;

                return locale
            }
        },
        /* Menus */
        getMainMenu: (state, getters) => {
            if(state.content != null){
                const locale = getters.getLocale
                return state.content.menus.main_menu[locale]
            }
        },
        /* Options */
        getOptions: (state) => {
            if(state.content != null){
                return state.content.options
            }
        },
        getOptionsFavicon: (state) => {
            if(state.content != null){
                return state.content.options.favicon
            }
        },
        getSeoTitleTemplate: (state) => {
            if(state.content != null){
                return state.content.options.seo_title_template
            }
        },
        getPublicPath: (state) => {
            return (process.env.VUE_APP_PUBLIC_PATH === undefined || process.env.VUE_APP_PUBLIC_PATH === null) ? '' : process.env.VUE_APP_PUBLIC_PATH
        },
        /* Specific getters */
        getPageIdBySlugAndParentAndLang: (state) => (slug, parent, lang) => {
            if(state.content != null){
                let result
                result = state.content.pages.find(page => page.slug === slug && page.parent_id === parent && page.lang === lang)
                if(result === undefined){
                    result = state.content.posts.find(post => post.slug === slug && post.lang === lang)
                }
                return result
            }
        },
        getLogoBackground: (state) => {
            if(state.content.options.background_logo.sizes.large){
                return state.content.options.background_logo.sizes.large;
            } else return "";
        }
    }
})
