<template>
<section id="photo"
         class="section is-paddingless">
	<div class="columns is-paddingless">
		<div class="column is-10 is-paddingless">
			<div class="background"
			     :style="{ backgroundImage: 'url(' + data.photo.url + ')' }"></div>
		</div>
	</div>
</section>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
.column
	+mobile
		margin: 20px 0 0 0
#photo
	margin: 80px 0
	+mobile
		margin: 0
.background
	width: 100%
	height: 400px
	background-size: cover
	background-position: center
	+mobile
		height: 250px

</style>
