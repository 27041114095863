<template>
<section id="slider">
	<div class="columns">
		<div class="column is-8 is-offset-2 is-10-touch is-offset-1-mobile">
			<div class="hooper">
				<div v-for="(item, index) in data.repeater"
				       :key="index"
				       class="slide"
				       :style="{backgroundImage: 'url('+backgroundUrl(index)+')'}">
					   <ul class="hooper-list">
						   <li class="hooper-slide">{{ item.title }}</li>
					   </ul>
				</div>
			</div>
			<!-- <hooper :infiniteScroll="true"
			        ref="hooper">
				<slide v-for="(item, index) in data.repeater"
				       :key="index"
				       class="slide"
				       :style="{backgroundImage: 'url('+backgroundUrl(index)+')'}">
					{{ item.title }}
				</slide>
			</hooper>
			<button class="next-button"><img src="@/assets/Arrow_green.svg"
				     alt="Next slide"
				     @click.prevent="nextSlide()"></button> -->
		</div>
	</div>
</section>
</template>

<script>
import {
	Hooper,
	Slide,
	Navigation as HooperNavigation
} from 'hooper';

import 'hooper/dist/hooper.css';

export default {
	components: {
		Hooper,
		Slide,
		HooperNavigation
	},
	methods: {
		backgroundUrl(index) {
			let i = index + 1;
			return require("@/assets/0" + i + '.svg');
		},
		nextSlide() {
			this.$refs.hooper.slideNext();
		}
	}
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

#slider
	margin-top: 30vh
	position: relative
	.index
		position: absolute
		width: 260px
		overflow: visible
		margin: -15rem -15rem
	.hooper-list
		width: 100%
		height: 100%
		li
			font-size: 3rem
			color: $mainblue
			+touch
				font-size: 1.6rem
			&::before
				content: ''
.slide
	background-repeat: no-repeat
	padding: 80px 0 0 80px
	background-size: 250px 250px
	width: 100%
	+touch
		padding: 80px 40px 80px 0
	+mobile
		background-image: none !important
		padding: 80px 15px
.hooper
	height: 320px
.next-button
	background: transparent
	border: 0
	margin-left: 5rem
	+mobile
		margin: 0.5rem
	img
		width: 55px
</style>
