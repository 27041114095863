<template>
	<section id="quote" :style="{ backgroundImage: 'url(' + data.photo.url + ')' }">
		<h2 class="is-size-2 is-size-4-mobile has-text-centered">{{ data.quote }}</h2>
		<p class="is-size-5 is-size-6-mobile has-text-centered">{{ data.author }}</p>
	</section>
</template>

<script>
export default {
}
</script>

<style lang="sass">
@import "@/styles/framework/variables.sass"

#quote
	width: 100%
	padding: 200px 0
	background-color: $grey
	background-size: cover
	background-position: center center
	background-attachment: fixed
	+mobile
		padding: 80px 0
	h2
		color: $mainblue
	p
		color: $mint

</style>
