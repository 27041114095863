<template>
    <section
        class="section post-list"
        :class="className"
        :id="`section-${idNumber}`"
    >
        <div class="container">
            <div class="columns">
                <div
                    class="column is-paddingless image-wrapper is-4 post"
					v-for="(item,key) in posts"
                >
                    <figure
                        class="image is-16by9"
                        v-if="item.thumbnail"
                    >
                        <img
                            :src="item.thumbnail"
                            :alt="item.title"
                            @click="$router.push(item.link)"
                        >
                    </figure>
					<h1
                        class="subtitle is-size-4"
                        @click="$router.push(item.link)"
                        v-html="item.title"
                    ></h1>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div class="columns">
                <div class="column has-text-centered">
                    <router-link :to="`/${postsPage}/`" class="button">See all Posts</router-link>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
export default {
    computed: {
        posts() {
            return this.$store.getters.getPosts.slice(0,3)
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        }
    }
}
</script>

<style lang="sass">
@import "@/styles/framework/variables.sass"

.post-list
	background-color: $grey
	padding: 80px 0 !important
	.post
		background-color: #fff
		margin: 0 10px
		h1
			padding: 30px
			color: $mainblue
</style>
