<template>
<div id="app">

    <preloader/>

	<aside class="aside-panel">
		<div class="left-panel"
		     :class="{ height: scrolled }"></div>
		<div class="arrow"
		     :class="{ opposite: scrolled }"
		     @click="handleScroll"><img src="@/assets/Arrow_white.svg"
			     class="arrow-image"
			     alt="Arrow"></div>
	</aside>
	<div class="left-text">
		<span class="is-size-6"
		      :class="{ colorswitch: scrolled }">A TRUSTED PARTNER IN RELIABLE INVESTMENTS</span>
	</div>

	<the-header />

	<transition name="fade"
	            mode="out-in">
		<router-view :key="$route.params.id" />
	</transition>

	<the-footer />
	<div class="topline"></div>
	<div class="rightline"></div>
</div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import Preloader from '@/components/Preloader.vue'
import VueScrollTo from 'vue-scrollto'

export default {
	data() {
		return {
			scrolled: false
		}
	},
	components: {
		'the-header': TheHeader,
        'the-footer': TheFooter,
        'preloader': Preloader
	},
	metaInfo() {
		return {
			titleTemplate: this.seoTitleTemplate,
			title: this.title,
			link: [{
				rel: 'icon',
				href: this.optionsFavicon
			}]
		}
	},
	computed: {
		page() {
			let page = this.$store.getters.getPageByPath(this.$route.path)
			if (page === undefined) {
				this.$router.push({
					name: 'error404'
				})
			} else {
				return this.$store.getters.getPageByPath(this.$route.path)
			}
		},
		title() {
			if (this.page !== undefined) {
				return this.page.title
			} else {
				return ''
			}
		},
		seoTitleTemplate() {
			return this.$store.getters.getSeoTitleTemplate
		},
		optionsFavicon() {
			return this.$store.getters.getOptionsFavicon
		}
	},
	mounted() {
		window.addEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll() {
			if (window.pageYOffset != 0) {
				this.scrolled = true
			} else {
				this.scrolled = false
			}
		},
		handleScroll: function () {
			if (window.scrollY > 0) {
				VueScrollTo.scrollTo({x: 0, y: 0}, 100);
			}else{
				VueScrollTo.scrollTo('#section-4', 100);
			}

		}
	}
}
</script>
<style lang="sass">
@import "@/styles/framework/variables.sass"
.aside-panel
    +mobile
        display: none
.left-text
    +mobile
        display: none
#app
    +mobile
        padding-left: 0 !important
.topline
    +mobile
        display: none
.rightline
    +mobile
        display: none
.arrow
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	&-image
		transform: rotate(90deg)
		width: 80%
		transition: transform $fast
	&.opposite
		.arrow-image
			transform: rotate(-90deg) !important
*
	scroll-behavior: smooth
</style>
