<template>
    <transition name="fade">
        <div class="preloader" v-if="active">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            	 viewBox="0 0 232 253.6" style="enable-background:new 0 0 232 253.6;" xml:space="preserve">
                 <defs>
                   <svg:style>
                     .outline {
                         stroke-dasharray: 900;
                         stroke-width: 1;
                         animation: dash 5.5s linear;
                         animation-fill-mode: forwards;
                     }
                     @keyframes dash {
                         0% {
                            stroke-dashoffset: 3797;
                            fill: #FFF;
                          }
                          50% {
                            stroke-dashoffset: 0;
                            fill: #FFF;
                          }

                          100% {
                              stroke-width: 0;
                              fill: #0A1C58;

                          }
                   </svg:style>
                 </defs>
            <path class="st0 outline" d="M121.2,214.6l-28-28c8.5,3.7,17.7,5.6,27,5.6c2,0,4-0.1,6-0.3l22.6,22.7L121.2,214.6z M103.5,179
            	c15.7,0,30.7-6.2,41.7-17.3c23-23.1,23-60.5,0-83.6l-18.3-18.4c-5.8-5.8-5.8-15.2,0-21c3.1-2.9,7.2-4.4,11.5-4.2
            	c3.4,0,6.6,1.5,8.8,4.1c2,2.6,3.8,5.5,5.3,8.4c2.6,4.8,4.9,9,11.9,13.3l-9.6,9.6l10,10c24.7,24.5,25,64.4,0.5,89.1
            	c-22,22.3-57.1,25-82.3,6.3C89.6,177.7,96.5,179,103.5,179 M65.1,158.4l-2.7-2.7c4.1,1.3,8.4,2,12.8,2c11.1,0,21.8-4.4,29.6-12.3
            	c16.3-16.4,16.3-43,0-59.4l-7.1-7.1c-12.5-12.4-12.6-32.6-0.2-45.1c10.9-11,28-12.6,40.7-3.9c-5.4-0.2-10.7,1.8-14.6,5.5
            	c-7.6,7.6-7.6,19.9,0,27.5L142,81.3c21.2,21.3,21.2,55.7,0,77c-21.2,21.2-55.5,21.3-76.7,0.1C65.2,158.5,65.2,158.4,65.1,158.4
            	 M48.9,142.1l-6.6-6.6c16.9-5.6,43.5-28.7,46-61.6c1.7,3.1,3.8,5.9,6.2,8.3l7.1,7.1c14.5,14.6,14.5,38.2,0,52.8
            	c-14.5,14.5-38,14.6-52.6,0.1C49,142.2,49,142.1,48.9,142.1 M155.5,214.6l-23.4-23.4c36.8-6.6,61.3-41.8,54.7-78.7
            	c-2.4-13.6-9-26.1-18.7-35.9l-6.7-6.7L172,59.2l-2.8-1.5c-7.9-4.2-9.9-7.8-12.7-12.9c-1.7-3.2-3.6-6.2-5.8-9.1l0,0
            	c-1.5-1.9-3-3.6-4.7-5.3c-14.3-14.3-37.4-14.3-51.7,0c-6.9,6.9-10.7,16.2-10.7,25.9v12.5c0,20.2-9.6,35.3-17.7,44.3
            	c-10.2,11.4-21.8,17.7-28.7,18.8l-4.5,0.7l28.9,29l0,0l10.7,10.7c0,0,0.1,0.1,0.1,0.1l42,42.1H33.8v4.6h165.4v-4.6L155.5,214.6z"  stroke="#0A1C58" stroke-width="1"/>
            </svg>
        </div>
    </transition>
</template>

<script>
export default{
    data(){
        return{
            active: true
        }
    },
    created(){
        setTimeout(() => {
            this.active = false;
        }, 3900);
    }
}
</script>

<style lang="scss">
@import "@/styles/framework/variables.sass";

.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    z-index: 999999;
    svg {
        height: 250px;
        position: fixed;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include touch {
            height: 200px;
        }
        @include mobile {
            height: 150px;
        }
    }
}


</style>
