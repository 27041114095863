<template>
    <div>
		<div class="logo" :class="{ swap: scroll }">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 117">
			  <defs>
			    <svg:style lang="css">
			      .a {
			        fill: #3bd4ae;
			      }

			      .b {
			        fill: none;
			        stroke: #0a1c58;
			        stroke-miterlimit: 10;

			      }

			      .c {
			        fill: #0a1c58;
			      }
			  </svg:style>
			  </defs>
			  <title>SPARROW Logo Web</title>
			  <g>
			    <path class="a" d="M220.91,83.41a8.47,8.47,0,0,1,8.71-8.59,8.2,8.2,0,0,1,7.19,4.08l-1.48.86a6.35,6.35,0,0,0-5.71-3.32,7,7,0,1,0,0,14,6.37,6.37,0,0,0,5.71-3.32l1.48.83a8.17,8.17,0,0,1-7.19,4.1,8.47,8.47,0,0,1-8.71-8.59"/>
			    <path class="a" d="M249.62,77.17l3.34,8.9h-6.69Zm-.86-2.06-6.38,16.61h1.76l1.54-4.08h7.88l1.54,4.08h1.75l-6.38-16.61Z"/>
			    <path class="a" d="M273.23,80.33a3.48,3.48,0,0,0-3.56-3.66H265.4V84h4.27a3.49,3.49,0,0,0,3.56-3.65m1.66,0a5.11,5.11,0,0,1-5.22,5.22H265.4v6.16h-1.66V75.11h5.93a5.09,5.09,0,0,1,5.22,5.22"/>
			    <rect class="a" x="282.48" y="75.11" width="1.66" height="16.61"/>
			    <polygon class="a" points="291.26 75.11 291.26 76.67 296.36 76.67 296.36 91.72 298.02 91.72 298.02 76.67 303.12 76.67 303.12 75.11 291.26 75.11"/>
			    <path class="a" d="M314.63,77.17l3.35,8.9h-6.7Zm-.85-2.06-6.39,16.61h1.76l1.54-4.08h7.88l1.54,4.08h1.76l-6.39-16.61Z"/>
			    <polygon class="a" points="328.75 75.11 328.75 91.72 338 91.72 338 90.15 330.41 90.15 330.41 75.11 328.75 75.11"/>
			  </g>
			  <path class="b" d="M59.79,15.48A9.22,9.22,0,0,0,62.49,22l1.92,1.92A10.66,10.66,0,0,1,49.33,39m21.51,12.3,7.38,7.37M55.35,45,69,58.66M75.52,9c3.51,3.51,2.36,5.34,6.39,7.46l-2.73,2.72,2.25,2.25A17.57,17.57,0,1,1,56.58,46.24l-9.81-9.81c4.23-.68,13-7.48,13-17.58V15.48A9.21,9.21,0,0,1,75.52,9m.74.84a4.6,4.6,0,0,0-5.9,7l2.28,2.29,2.65,2.65A15.27,15.27,0,0,1,53.7,43.36"/>
			  <g>
			    <path class="c" d="M123.48,43a16,16,0,0,1-4.09-.45,18.83,18.83,0,0,1-3.47-1.34l-.39-6.4h.62L118.06,39a10.15,10.15,0,0,0,2.73,1.69,7.24,7.24,0,0,0,2.69.53,5.12,5.12,0,0,0,3.6-1.21,4.26,4.26,0,0,0,1.31-3.28,4.26,4.26,0,0,0-.76-2.69,6.91,6.91,0,0,0-2.18-1.73c-1-.51-2.08-1.09-3.38-1.74A25.38,25.38,0,0,1,119,28.71,6.19,6.19,0,0,1,117,26.63a6,6,0,0,1-.67-3,5.69,5.69,0,0,1,.65-2.6,8.31,8.31,0,0,1,1.75-2.3,8.63,8.63,0,0,1,2.5-1.63,6.94,6.94,0,0,1,2.86-.61,12.07,12.07,0,0,1,3.32.43,18.94,18.94,0,0,1,3.11,1.21l.39,6.24h-.62l-1.79-4.1a13.57,13.57,0,0,0-2.38-1.46,5.75,5.75,0,0,0-2.34-.45A3.74,3.74,0,0,0,121,19.55a4,4,0,0,0-1.11,2.89,4,4,0,0,0,1.21,3.1,16.33,16.33,0,0,0,3.78,2.35,37.18,37.18,0,0,1,3.9,2.17A6.81,6.81,0,0,1,131,32.34a6.43,6.43,0,0,1,.74,3.27,6.45,6.45,0,0,1-.78,3.22,7.72,7.72,0,0,1-2,2.32,9,9,0,0,1-2.69,1.4,9.27,9.27,0,0,1-2.79.47"/>
			    <path class="c" d="M146,27.39a7,7,0,0,0-1.93.29,7.75,7.75,0,0,0-1.93.88v11a6.89,6.89,0,0,0,2.38,1.29,9.79,9.79,0,0,0,2.69.35,4.16,4.16,0,0,0,3.24-1.58c.9-1,1.36-2.81,1.36-5.28a8.63,8.63,0,0,0-.78-3.94,5.14,5.14,0,0,0-2.1-2.24,6.05,6.05,0,0,0-2.93-.72M136.54,51.6V51L139,49.73V29.14l-2.34-2v-.27l4.84-2.14h.23l.31,2.38h.28a10.87,10.87,0,0,1,2.84-1.82,7.81,7.81,0,0,1,3-.56,6.2,6.2,0,0,1,3.5,1,6.82,6.82,0,0,1,2.4,2.87A10.16,10.16,0,0,1,154.9,33a11.51,11.51,0,0,1-1.19,5.44,8.36,8.36,0,0,1-3.22,3.41A8.72,8.72,0,0,1,146.05,43a18.2,18.2,0,0,1-3.94-.39v7.1L144.88,51v.63Z"/>
			    <path class="c" d="M162.62,38.19c0,1.61,1,2.41,2.92,2.41a8.87,8.87,0,0,0,1.85-.19,11.89,11.89,0,0,0,2.09-.7V33.94a22.71,22.71,0,0,0-4.56,1.22A4,4,0,0,0,163,36.55a3.26,3.26,0,0,0-.42,1.64m1.2,4.83a4.44,4.44,0,0,1-3-1.07,3.8,3.8,0,0,1-1.2-3A4.5,4.5,0,0,1,161,35.65a10.1,10.1,0,0,1,4.77-2.18l3.71-.86V29.77a3,3,0,0,0-1.07-2.29,3.61,3.61,0,0,0-2.44-.95,2.41,2.41,0,0,0-2.07.8,3.5,3.5,0,0,0-.58,2.12v1.09l-.2.2h-2a1.15,1.15,0,0,1-.8-.2c-.15-.12-.22-.42-.22-.89a3.57,3.57,0,0,1,.51-1.76,4.64,4.64,0,0,1,1.56-1.56,13.59,13.59,0,0,1,2.28-1.16A6.72,6.72,0,0,1,167,24.7a9.28,9.28,0,0,1,2.67.39,4.54,4.54,0,0,1,2.13,1.3A3.75,3.75,0,0,1,172.6,29V40a2.21,2.21,0,0,0,.78.47,4,4,0,0,0,1.4.19h1.48l.12.08v.12a4.48,4.48,0,0,1-1.39,1.5,3.79,3.79,0,0,1-2.24.64,3,3,0,0,1-2-.64,3.53,3.53,0,0,1-1.07-1.7h-.23A8.17,8.17,0,0,1,167,42.36a7.69,7.69,0,0,1-3.14.66"/>
			    <path class="c" d="M179.61,42.63V42l2.46-1.25V29.14l-2.34-2v-.27l4.83-2.14h.24l.35,3.31h.16A11.08,11.08,0,0,1,187,25.85a3.89,3.89,0,0,1,1.43-.94,4.33,4.33,0,0,1,1.32-.21,2.94,2.94,0,0,1,.72.11,5.75,5.75,0,0,1,.68.24,3.74,3.74,0,0,1,.8.47.76.76,0,0,1,.37.62,1.76,1.76,0,0,1-.15.7l-.78,1.91h-.28l-1.48-.58a2.68,2.68,0,0,0-.83-.24c-.28,0-.54,0-.8,0a3.27,3.27,0,0,0-2.77,1.68V40.76L188,42v.62Z"/>
			    <path class="c" d="M195.87,42.63V42l2.45-1.25V29.14l-2.33-2v-.27l4.83-2.14h.23L201.4,28h.16a11.07,11.07,0,0,1,1.66-2.16,3.87,3.87,0,0,1,1.42-.94A4.35,4.35,0,0,1,206,24.7a3.08,3.08,0,0,1,.72.11,5.75,5.75,0,0,1,.68.24,4.3,4.3,0,0,1,.8.47.79.79,0,0,1,.37.62,1.62,1.62,0,0,1-.16.7l-.78,1.91h-.27l-1.48-.58a2.82,2.82,0,0,0-.84-.24c-.27,0-.54,0-.8,0a3.3,3.3,0,0,0-2.77,1.68V40.76L204.21,42v.62Z"/>
			    <path class="c" d="M221.17,41.34a3.54,3.54,0,0,0,2.57-1,5.65,5.65,0,0,0,1.48-2.52,11,11,0,0,0,.47-3.21,13.88,13.88,0,0,0-.57-4,7.07,7.07,0,0,0-1.79-3.12,4.37,4.37,0,0,0-3.18-1.21,3.63,3.63,0,0,0-2.61,1A6,6,0,0,0,216,29.8a10.77,10.77,0,0,0-.49,3.24,13.55,13.55,0,0,0,.59,4A7.23,7.23,0,0,0,218,40.14a4.43,4.43,0,0,0,3.2,1.2M220.62,43a8.15,8.15,0,0,1-7.5-4.48,10,10,0,0,1-1.07-4.68,10,10,0,0,1,1.07-4.68,8.16,8.16,0,0,1,3-3.27,8.46,8.46,0,0,1,12,3.27,10.77,10.77,0,0,1,0,9.36,8.33,8.33,0,0,1-3,3.27,8.23,8.23,0,0,1-4.5,1.21"/>
			    <polygon class="c" points="239.76 42.63 233.64 26.92 231.03 25.71 231.03 25.09 240.35 25.09 240.35 25.71 237.15 26.88 237.15 27.11 241.21 37.91 241.36 37.91 246.9 25.09 247.72 25.09 253.25 37.8 253.41 37.8 257.34 27.35 257.34 27.11 254.54 25.71 254.54 25.09 262.18 25.09 262.18 25.71 259.29 27.08 252.78 42.63 251.97 42.63 246.35 29.92 240.58 42.63 239.76 42.63"/>
			    <polygon class="c" points="263.78 42.63 263.78 42.01 266.23 40.76 266.23 17.84 263.89 15.81 263.89 15.54 269.12 13.39 269.35 13.39 269.35 40.76 271.81 42.01 271.81 42.63 263.78 42.63"/>
			    <path class="c" d="M279.18,38.19c0,1.61,1,2.41,2.92,2.41a8.87,8.87,0,0,0,1.85-.19,11.89,11.89,0,0,0,2.09-.7V33.94a23,23,0,0,0-4.57,1.22,4,4,0,0,0-1.87,1.39,3.26,3.26,0,0,0-.42,1.64m1.2,4.83a4.44,4.44,0,0,1-3-1.07,3.81,3.81,0,0,1-1.21-3,4.51,4.51,0,0,1,1.43-3.32,10.1,10.1,0,0,1,4.77-2.18l3.71-.86V29.77A3,3,0,0,0,285,27.48a3.56,3.56,0,0,0-2.43-.95,2.41,2.41,0,0,0-2.07.8,3.5,3.5,0,0,0-.58,2.12v1.09l-.2.2h-2a1.12,1.12,0,0,1-.79-.2c-.15-.12-.22-.42-.22-.89a3.57,3.57,0,0,1,.51-1.76,4.64,4.64,0,0,1,1.56-1.56A12.87,12.87,0,0,1,281,25.17a6.72,6.72,0,0,1,2.55-.47,9.28,9.28,0,0,1,2.67.39,4.54,4.54,0,0,1,2.13,1.3,3.74,3.74,0,0,1,.81,2.56V40a2.41,2.41,0,0,0,.78.47,4.09,4.09,0,0,0,1.41.19h1.48l.12.08v.12a4.58,4.58,0,0,1-1.39,1.5,3.79,3.79,0,0,1-2.24.64,2.94,2.94,0,0,1-2-.64,3.45,3.45,0,0,1-1.07-1.7H286a8.17,8.17,0,0,1-2.52,1.68,7.73,7.73,0,0,1-3.14.66"/>
			    <path class="c" d="M296,42.63V42l2.45-1.25V29.14l-2.34-2v-.27L301,24.7h.23l.35,2.88h.2a11.43,11.43,0,0,1,2.86-2.18,6.62,6.62,0,0,1,3.06-.7,5.88,5.88,0,0,1,3.76,1.15,4.05,4.05,0,0,1,1.47,3.41v11.5L315.35,42v.62H307V42l2.77-1.25V31.09a3.2,3.2,0,0,0-1.08-2.61,4.17,4.17,0,0,0-2.78-.9,8.9,8.9,0,0,0-2.32.35,4.88,4.88,0,0,0-2,1V40.76L304.36,42v.62Z"/>
			    <path class="c" d="M322.45,34.17c0,2.16.57,3.72,1.71,4.7a6.12,6.12,0,0,0,4.09,1.46,7,7,0,0,0,1.93-.29,7.78,7.78,0,0,0,1.93-.88V28.25A7.25,7.25,0,0,0,330.26,27a4.83,4.83,0,0,0-2-.41,5.58,5.58,0,0,0-2.87.76,5.34,5.34,0,0,0-2.1,2.41,10.1,10.1,0,0,0-.8,4.39M326.11,43a6.48,6.48,0,0,1-3.49-1,6.7,6.7,0,0,1-2.42-2.75,9.56,9.56,0,0,1-.87-4.23,11.58,11.58,0,0,1,1.28-5.64A8.88,8.88,0,0,1,324,25.91a9.33,9.33,0,0,1,4.68-1.21,17.61,17.61,0,0,1,1.91.09,12.86,12.86,0,0,1,1.48.26V17.84l-2.53-2v-.27L335,13.39h.23V40.88L338,42v.62h-5.5l-.31-2h-.27a10.77,10.77,0,0,1-2.87,1.81,7.62,7.62,0,0,1-2.94.57"/>
			  </g>
			  <line class="b" y1="58.66" x2="338" y2="58.66"/>
			</svg>
		</div>
		<div class="columns is-paddingless space">
			<div class="column is-paddingless">
				<div class="right-top">
					<div class="social">

					</div>
					<ul>
						<li>Neuhofstrasse 5A</li>
						<li>6340 Baar, Switzerland</li>
						<li>+41 41 769 30 30</li>
					</ul>
				</div>

			</div>
		</div>
    </div>
</template>

<script>
export default {
	data() {
		return{
			scroll: false
		}
	},
	mounted () {
	  window.addEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll () {
	      if (window.pageYOffset != 0) {
			this.scroll = true
	      }
		  else {
		  	this.scroll = false
		  }
	  	}
	}
}
</script>

<style scoped lang="sass">
@import "@/styles/framework/variables.sass"

.logo
	position: absolute
	top: 130px
	left: 80px
	transition: all 0.9s ease
	+mobile
		left: 0
		top: 80px
	svg
		width: 320px
		+mobile
			width: 260px
.space
	margin-top: 20px
	.right-top
		ul
			text-align: right
			margin: 60px 100px
			color: $mainblue
			+mobile
				display: none
</style>
