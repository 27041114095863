<template>
    <footer class="footer">
		<div class="container is-fluid">
			<div class="columns">
				<div class="column">
					<h3>Office</h3>
					<p>Sparrowland Capital</p>
					<p>Neuhofstrasse 5A</p>
					<p>6340 Baar, Switzerland</p>
				</div>
				<div class="column">
					<h3>Phone</h3>
					<p>+41 41 769 30 30</p>
				</div>
				<!-- <div class="column">
					<h3>Investment Inquiries</h3>
					<p>invest@sparrowlandcapital.ch</p>
				</div> -->
			</div>
			<hr>
			<div class="columns">
				<div class="column">
					<span>©2019 Sparrowlandcapital / All Rights Reserved</span>
				</div>
				<div class="column">

				</div>
			</div>
		</div>
    </footer>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
@import "@/styles/framework/variables.sass"

hr
	background-color: $mint
	height: 1px
.footer
	background-color: $mainblue
	padding: 80px 0
	+touch
		padding: 50px 0
.columns
	.column
		+touch
			margin: 0 40px
		h3
			color: $mint
			text-transform: uppercase
			margin-bottom: 20px
		p, span
			color: #fff

</style>
